import { gtmParameters } from 'packages/util/getParameters';
import moengage from '@moengage/web-sdk';
import { useUserState } from '../context/user';

export const WHATSAPP_NUMBER = '918097454422';
export const CALL_NUMBER = '8448449442';

const useSocialEvent = () => {
  const { currentUser } = useUserState();
  const handleSocialEvent = (eventName) => {
    moengage.track_event(eventName, {
      phone_number: currentUser?.mobilenumber,
      ...(eventName === 'call_icon_click'
        ? { ...gtmParameters()}
        : ''),
    });
  };
  return {
    handleSocialEvent,
  };
};

export default useSocialEvent;
