import * as apollo from '@apollo/client';
import { ProductsFragment } from '../../packages/framework/hooks/RootComponents/Category/categoryFragments.gql';
const apollo1: any = apollo;
const g = apollo1?.default?.gql || apollo1?.gql;

export const RESOLVE_URL = g`
  query ResolveURL($url: String!) {
    route(url: $url) {
      relative_url
      redirect_code
      type
    }
  }
`;

export const GET_CATEGORY_CMS_DATA = g`
  query getCmsCategoryData($identifiers: [String]!) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const GET_CATEGORY1 = g`
  query GetCategories(
    $id: String!
    $pageSize: Int!
    $currentPage: Int!
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
  ) {
    categories(filters: { category_uid: { eq: $id } }) {
      items {
        uid
        default_color {
          label
          value
        }
        default_fan_type {
          label
          value
        }
        default_sweep_size {
          label
          value
        }
      }
    }
    products(
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filters
      sort: $sort
    ) {
      ...ProductsFragment
    }
  }
  ${ProductsFragment}
`;

export const GET_CATEGORY_DATA = g`
  query ResolveURL($url: String!) {
    route(url: $url) {
      relative_url
      redirect_code
      type
      ... on CategoryInterface {
        uid
        meta_title
        meta_keywords
        meta_description
        url_path
        canonical_url
        seo_canonical_url
        category_footer_content
        category_desktop_img: image
        category_mobile_img
        codilar_category_banner_slider {
          title
          is_show_title
          is_enabled
          slider_id
          banners {
            slider_id
            sort_order
            is_mobile
            resource_type
            resource_path
            link
            alt_text
            title
            thumbnail
          }
        }
        name
        breadcrumbs {
          category_uid
          category_name
          category_level
          category_url_path
        }
      }
    }
  }
`;

export const GET_CATEGORY_OTHER_DATA = g`
  query getCategoryOtherData(
    $uid: String!
    $categoryIdFilter: FilterEqualTypeInput!
  ) {
    categoryList(filters: { category_uid: { eq: $uid } }) {
      uid
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      description
      category_content
      explore_series_mapping {
        id
        category_name
        category_url
        category_explore_img
      }
    }
    allCategories: categories(
      filters: { parent_category_uid: { eq: "Mg==" } }
    ) {
      total_count
      items {
        uid
        name
        url_path
        url_key
        children {
          uid
          name
          url_path
          url_key
        }
      }
    }
    products(filter: { category_uid: $categoryIdFilter }) {
      aggregations {
        label
        count
        attribute_code
        options {
          label
          value
        }
        position
      }
      sort_fields {
        options {
          label
          value
        }
      }
    }
  
  }
`;

export const GET_PRODUCT_DETAIL_QUERY_1 = g`
  query getProductDetailForProductPage($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey } }) {
      items {
        id
        uid
        RequestLiveDemoCTA
        hero_variant
        ecommerce_redirect_text
        ecommerce_redirect
        ecommerce_redirect_link
        speciai_discount
        saleable_on_both
        categories {
          name
          url_key
          url_suffix
          uid
          canonical_url
          breadcrumbs {
            category_uid
          }
          explore_series_mapping {
            id
            category_name
            category_url
            category_explore_img
          }
        }
        description {
          html
        }
        id
        uid
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        media_gallery_entries {
          uid
          label
          position
          disabled
          file
          video_content {
            media_type
            video_provider
            video_url
            video_title
            video_description
            video_metadata
            video_uploaded
          }
        }
        meta_title
        meta_description
        tech_specs_attributes {
          label
          value
          code
          position
        }
        additional_info_attributes {
          label
          value
          code
          position
        }
        name
        canonical_url
        product_title
        only_x_left_in_stock
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        sku
        sl1_experience
        battery
        attr_5_unlocking_modes
        product_dimensions
        safety_mechanisms
        security
        material_used
        app
        operating_ambient_temperature
        maximum_number_of_fingerprints
        low_battery_alarm
        operating_current
        standby_current
        small_image {
          url
        }
        faqCategoryList {
          faq_category_id
          faq_category_name
        }
        ProductFaq {
          answer
          question
          faq_id
          faq_category {
            faq_category_name
            faq_category_id
          }
        }
        mgPdpBanner
        coarseMode
        advanceSafety
        fourJars
        handsFreeOperation
        heavyDuty
        sleekDesign
        chefReviews
        knowMore
        reviews {
          items {
            average_rating
            created_at
            nickname
            summary
            text
            ratings_breakdown {
              name
              value
            }
          }
        }
        only_x_left_in_stock
        custom_attributes {
          selected_attribute_options {
            attribute_option {
              uid
              label
              is_default
            }
          }
          entered_attribute_value {
            value
          }
          attribute_metadata {
            uid
            code
            label
            attribute_labels {
              store_code
              label
            }
            data_type
            is_system
            entity_type
            ui_input {
              ui_input_type
              is_html_allowed
            }
            ... on ProductAttributeMetadata {
              used_in_components
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL_QUERY_2 = g`
  query getProductDetailForProductPage($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey } }) {
      items {
        ... on ConfigurableProduct {
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          configurable_options {
            attribute_code
            attribute_id
            uid
            label
            position
            # eslint-disable-next-line @graphql-eslint/require-id-when-available
            values {
              uid
              default_label
              label
              store_label
              use_default_value
              value_index
              swatch_data {
                ... on ImageSwatchData {
                  thumbnail
                }
                value
              }
            }
          }
          variants {
            attributes {
              code
              value_index
              label
              uid
            }
            # eslint-disable-next-line @graphql-eslint/require-id-when-available
            product {
              product_title
              uid
              swatch_image
              name
              small_image {
                url
              }
              meta_description
              url_key
              reviews {
                items {
                  average_rating
                  created_at
                  nickname
                  summary
                  text
                  ratings_breakdown {
                    name
                    value
                  }
                }
              }
              only_x_left_in_stock
              # eslint-disable-next-line @graphql-eslint/require-id-when-available
              ecommerce_redirect_text
              ecommerce_redirect
              ecommerce_redirect_link
              saleable_on_both
              tech_specs_attributes {
                label
                value
                code
                position
              }
              additional_info_attributes {
                label
                value
                code
                position
              }
              media_gallery_entries {
                uid
                disabled
                file
                label
                position
                video_content {
                  media_type
                  video_provider
                  video_url
                  video_title
                  video_description
                  video_metadata
                  video_uploaded
                }
              }
              speed
              steel_jars
              chopper_jars
              warranty
              consumption_in_watts
              air_delivery
              rpm
              service_value
              input_voltage
              frequency
              power_factor
              no_of_blades
              sku
              stock_status
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              custom_attributes {
                selected_attribute_options {
                  attribute_option {
                    uid
                    label
                    is_default
                  }
                }
                entered_attribute_value {
                  value
                }
                attribute_metadata {
                  uid
                  code
                  label
                  attribute_labels {
                    store_code
                    label
                  }
                  data_type
                  is_system
                  entity_type
                  ui_input {
                    ui_input_type
                    is_html_allowed
                  }
                  ... on ProductAttributeMetadata {
                    used_in_components
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL_QUERY_3 = g`
  query getProductDetailForProductPage($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey } }) {
      items {
        tech_specs {
          attribute_key
          attribute_value
          product_img
        }
        average_rating_on_amazon
        link_to_amazon_reviews
        average_rating_on_flipkart
        link_to_flipkart_link
        average_rating_on_google
        link_to_google_link
        average_rating_on_paytm
        link_to_paytm_link
        stock_status
        url_key
        url_path
        product_attribute_banner
        pdp_offer_code
        buying_guide_button
        attribute_set_id
        customer_reviews
        customers_gallery_text
        customers_gallery
        acheivements
        smart_remote
        smart_remote_content
        buy_from
        buy_from_text
        choose_us
        choose_us_text
        video_gallery
        collections_gallery
        collections_title
        droll_image_title
        droll_image_gallery
        jar_occasions
        youtube_video_1
        youtube_video_2
        slow_mode
        slow_mode_grinds
        bearing
        remote_control
        warranty
        input_voltage
        consumption_in_watts
        control
        material_of_body
        material_of_jar
        power_cord
        number_of_jars
        still_have_doubts
        style_and_savings
        fan_comparison
        amazon_reviews
        google_reviews
        flipkart_reviews
        paytm_reviews
        total_reviews_count
        avg_rating
        review_count
        emergency_backup_for_drained_b
        compatible_door_thickness
        bluetooth_range
        total_data_security_content
        ways_to_enter_your_smart_home
        atomberg_service_guarantee
        key_features
      }
    }
  }
`;

export const GET_STORE_CONFIG = g`
  query GetBreadcrumbs($category_id: String!) {
    categories(filters: { category_uid: { in: [$category_id] } }) {
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        breadcrumbs {
          category_uid
          # We may not need level if \`breadcrumbs\` is sorted.
          category_level
          category_name
          category_url_path
        }
        uid
        name
        url_path
      }
    }
    storeConfig {
      store_code
      magento_wishlist_general_is_enabled
      razorpay_key_id
      razorpay_merchant_name_override
      store_code
      category_url_suffix
    }
  }
`;

export const CMS_PAGE_DATA = g`
  query ResolveURL($url: String!) {
    route(url: $url) {
      relative_url
      redirect_code
      type
      ... on CmsPage {
        identifier
      }
    }
  }
`;
