import { ApolloError } from '@apollo/client';
import { sendGtmEvent, sendUserLoaded, sha256 } from 'packages/util/gtm';
import BrowserPersistence from '../../../util/simplePersistence';
import { removeCart } from '../cart';

import actions from './actions';

import moengage from '@moengage/web-sdk';
import { gtmParameters } from 'packages/util/getParameters';
import { AnalyticsEvents } from 'packages/framework/util/logger/constants';
import { checkEmailAddress } from 'packages/framework/hooks/checkEmailAddress';

const IS_USER_LOGGED_IN_TRIGGERED = 'isUserLoginTriggered';

const storage = new BrowserPersistence();

export const signOut = (payload: any = {}) =>
  async function thunk(dispatch, getState, { apolloClient }) {
    const { revokeToken } = payload;

    const { user } = getState();

    if (revokeToken) {
      // Send mutation to revoke token.
      try {
        await revokeToken();
      } catch (error) {
        console.error('Error Revoking Token', error);
      }
    }
    if (user.currentUser) {
      moengage.destroy_session();
      storage.removeItem(IS_USER_LOGGED_IN_TRIGGERED);
      sendGtmEvent('Log Out', {
        mobile: user.currentUser.mobilenumber,
        email: user.currentUser.email,
      });
    }

    // Remove token from local storage and Redux.
    await dispatch(clearToken());
    await dispatch(actions.reset());
    await apolloClient.clearCacheData(apolloClient, 'cart');
    await apolloClient.clearCacheData(apolloClient, 'customer');

    // Now that we're signed out, forget the old (customer) cart.
    // We don't need to create a new cart here because we're going to refresh
    // the page immediately after.
    await dispatch(removeCart());
  };

export const getUserDetails = ({ fetchUserDetails, mode = null }) => {
  return async function thunk(...args) {
    const [dispatch, getState] = args;
    const { user } = getState();
      const storage = new BrowserPersistence();
      const isUserLoginAlreadyTriggered = storage.getItem(IS_USER_LOGGED_IN_TRIGGERED);
      const userToken = storage.getItem('signin_token')
    if (user.isSignedIn) {
      dispatch(actions.getDetails.request());
      try {
        const data = await fetchUserDetails();
        const customer = data.data?.customer;
        const email = customer?.email;
        const mobilenumber = customer?.mobilenumber;
        const first_name = customer?.firstname;
        const last_name = customer?.lastname;
        const total_no_of_orders = customer?.orders?.total_count;
        sendUserLoaded(data.customer);

        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = /android/.test(userAgent);
        const isIos = /iphone|ipad|ipod/.test(userAgent);
        const userId = await sha256(`+${mobilenumber}`);

        if(!isUserLoginAlreadyTriggered) {
        moengage.add_unique_user_id(`+${mobilenumber}`);
        moengage.track_event(AnalyticsEvents?.USER_LOGGED_IN, {
          Mode: 'Mobile'
        })
        const emailId = checkEmailAddress(email) || '';
        moengage.add_first_name(first_name || '');
        moengage.add_last_name(last_name || '');
        moengage.add_email(emailId || '');
        moengage.add_mobile(`+${mobilenumber}`);

        moengage.track_event(AnalyticsEvents?.LOGIN_EVENT_NAME, {
          phone_number: `+${mobilenumber}`,
          email_id: emailId || '',
          type_of_device: isAndroid ? 'Android' : isIos ? "IOS" : 'Desktop' ,
          ...gtmParameters(),
        });
        storage.setItem(IS_USER_LOGGED_IN_TRIGGERED, userToken)
      }

        sendGtmEvent('UserData', {
          user_status: 'Logged In',
          total_no_of_orders,
          mode: 'mobile',
          phone: mobilenumber,
          userId,
          email,
          first_name,
          last_name,
          date_of_birth: '',
          gender: '',
          city: '',
          state: '',
          address: '',
          last_order_date: '',
          loyalty_points: 0, // Data type should be number
          total_order_value: 0, // Data type should be number
        });
        if (mode) {
          sendGtmEvent('User Logged In', {
            mode: 'mobile',
            mobile: mobilenumber,
            email,
          });
        }
        dispatch(actions.getDetails.receive(data.data.customer));
      } catch (error) {
        console.log('error', error);
        const graphQLError = (error as any).graphQLErrors[0];

        if ('graphql-authorization' == graphQLError?.extensions.category) {
          await dispatch(signOut());
        }

        dispatch(actions.getDetails.receive(error));
      }
    } else {
      sendGtmEvent('UserData', {
        user_status: 'Guest User',
      });
    }
  };
};

export const resetPassword = ({ email }) =>
  async function thunk(...args) {
    const [dispatch] = args;

    dispatch(actions.resetPassword.request());

    // TODO: actually make the call to the API.
    // For now, just return a resolved promise.
    await Promise.resolve(email);

    dispatch(actions.resetPassword.receive());
  };

export const setToken = token =>
  async function thunk(...args) {
    const [dispatch] = args;

    // Store token in local storage.
    // TODO: Get correct token expire time from API
    // storage.setItem('signin_token', token, 3600);
    storage.setItem('signin_token', token, 31536000);

    // Persist in store
    dispatch(actions.setToken(token));
  };

export const clearToken = () =>
  async function thunk(...args) {
    const [dispatch] = args;

    // Clear token from local storage
    storage.removeItem('signin_token');

    // Remove from store
    dispatch(actions.clearToken());
  };
