import { useState, useEffect, useRef } from 'react';
import { getMarketingTickerContent } from 'src/hooks/MarketingStrip/marketingStrip';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';


const MarketingTickerStrip = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const textRefs = useRef([]);
  const { data } = getMarketingTickerContent();
  const { isDesktop } = useWindowSize();
  const { ticker_backgrount_color, ticker_font_color, ticker_speed } =
    STORE_CONFIG || {};

  const calculateDuration = (element, container) => {
    if (!element || !container) return null;

    const textWidth = element.offsetWidth;
    const containerWidth = container.offsetWidth;
    const totalDistance = textWidth + containerWidth;
    const finalSpeed = isDesktop
      ? (totalDistance * ticker_speed) / 2
      : totalDistance * ticker_speed;

    return finalSpeed;
  };

  useEffect(() => {
    textRefs.current.forEach(slideRef => {
      if (slideRef) {
        slideRef.style.opacity = '0';
        slideRef.style.animation = 'none';
      }
    });
    if (textRefs.current[currentIndex] && containerRef.current) {
      const duration = calculateDuration(
        textRefs.current[currentIndex],
        containerRef.current,
      );
      textRefs.current[currentIndex].style.opacity = '1';
      textRefs.current[
        currentIndex
      ].style.animation = `${styles.moveTicker} ${duration}ms linear infinite`;
      const timer = setTimeout(() => {
        setCurrentIndex(prevIndex =>
          prevIndex === data?.getHomePageTickerContent?.length - 1 ? 0 : prevIndex + 1,
        );
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, data]);

  return (
    <div
      className={`${styles.tickerContainer} ${
        ticker_backgrount_color ? `bg-[${ticker_backgrount_color}]` : 'bg-brand'
      } ${data?.getHomePageTickerContent?.length ? 'block' : 'hidden'}`}
      ref={containerRef}
    >
      {data?.getHomePageTickerContent?.map((item, index) => (
        <div
          key={index}
          className={`${styles.ticker} ${
            ticker_font_color ? `text-[${ticker_font_color}]` : 'text-black'
          }`}
          ref={el => (textRefs.current[index] = el)}
        >
          <Link to={item?.ticker_url ? item?.ticker_url : '#'}>
            {item.ticker_content}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MarketingTickerStrip;
