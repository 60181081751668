export const gtmParameters = () => {
	const utmParams = [
		'lt_utm_source',
		'lt_utm_medium',
		'lt_utm_campaign',
		'lt_utm_term',
		'lt_utm_content',
	];
	let gtmParams = {};
	utmParams?.forEach(param => {
		const value = localStorage.getItem(param);
		const utmGtmEventAttribute = param.replace('lt_', '');
		if(value) {
		gtmParams[utmGtmEventAttribute] = value || '';
		}
	});
	return gtmParams;
}