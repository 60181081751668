import { gql } from '@apollo/client';

export const ProductListingFragment = gql`
  fragment ProductListingFragment on Cart {
    id
    items {
      uid
      product {
        swatch_image
        only_x_left_in_stock
        product_qty
        uid
        primary_category
        name
        sku
        url_key
        stock_status
        categories {
          uid
          name
        }
        thumbnail {
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
             final_price {
              value
            }  
          }
        }
      }
      prices {
        row_total_including_tax {
          value
          currency
        }
        row_total {
          value
          currency
        }
        price {
          value
          currency
        }
        discounts {
          amount {
            value
            currency
          }
          label
        }
      }
      errors {
        code
        message
      }
      quantity
      ... on VirtualCartItem {
        customizable_options {
          label
          values {
            value
            label
          }
        }
      }
      ... on ConfigurableCartItem {
        configured_variant {
          only_x_left_in_stock
          product_qty
          uid
          stock_status
          sku
          thumbnail {
            url
          }
          price_range {
            minimum_price {
              regular_price {
                value
              }
              final_price {
                value
              }  
            }
          }
        }
        configurable_options {
          configurable_product_option_uid
          option_label
          configurable_product_option_value_uid
          value_label
        }
      }
    }
  }
`;
