import GenericShimmer from 'src/components/GenericShimmer';
import styles from './style.module.scss';
import CardShimmer from './CardShimmer';

interface CategoryLoadShimmerTypes {
}

const CategoryLoadShimmer = (props:CategoryLoadShimmerTypes) => {
  const isMixerGrinderPages =
  location?.pathname === '/mixer-grinder' || location?.pathname === '/smart-lock-sl-1';;
  return (
    <div className={styles.rootShimmerGenericShimmer}>
      <div className={styles.rootBannerShimmer}>
        <GenericShimmer height="100%" width="100%" count={1} />
      </div>
      <div className={styles.categorySidebarCardShimmer}>
        {!isMixerGrinderPages ? <div className={styles.sidebarShimmer}>
          <GenericShimmer height="100%" width="100%" count={1} />
        </div>:null}
        <CardShimmer  />
      </div>
    </div>
  );
};

export default CategoryLoadShimmer;
