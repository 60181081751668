import GenericShimmer from 'src/components/GenericShimmer';
import styles from './style.module.scss';

const MinicartShimmer = () => {
  return (
    <div className={styles.miniCartShimmerContainer}>
      <div className={styles.itemCountShimmer}>
        <GenericShimmer height={20} width="80%" count={1} />
      </div>
      <div className={styles.zipcodeShimmer}>
        <GenericShimmer height={40} width="100%" count={1} />
      </div>
      <div className={styles.productImagePriceShimmer}>
        <div className={styles.imageShimmer}>
          <GenericShimmer height="100%" width="100%" count={1} />
        </div>
        <div className={styles.priceShimmer}>
          <GenericShimmer height={20} width="56%" count={1} />
          <GenericShimmer height={20} width="50%" count={1} />
          <GenericShimmer height={20} width="70%" count={1} />
        </div>
      </div>
      <div className={styles.subTotalShimmer}>
        <GenericShimmer height={20} width="100%" count={1} />
      </div>
      <div className={styles.ctaShimmer}>
        <GenericShimmer height={50} width="100%" count={1} />
        <GenericShimmer height={50} width="100%" count={1} />
      </div>
    </div>
  );
};

export default MinicartShimmer;
