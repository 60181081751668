import React, { Suspense, useMemo } from 'react';
// import { useIntl } from 'react-intl';
import { useMagentoRoute } from 'packages/framework/hooks/MagentoRoute';
import ErrorView from 'packages/framework/components/ErrorView';
import RootShimmerComponent from 'packages/framework/RootComponents/Shimmer';
import { Outlet, useLocation } from 'react-router';
import CategoryLoadShimmer from './CategoryLoadShimmer';
import GenericShimmer from 'src/components/GenericShimmer';
import ProductCategoryShimmer from 'src/RootComponents/Category/CategoryBottomSection/ProductCategory/ProductCategoryShimmer';

const MESSAGES = new Map()
  .set(
    'NOT_FOUND',
    "Looks like the page you were hoping to find doesn't exist. Sorry about that.",
  )
  .set('INTERNAL_ERROR', 'Something went wrong. Sorry about that.');

const MagentoRoute = () => {
  const hookProps = useMagentoRoute();
  const {
    component: RootComponent,
    isLoading,
    isNotFound,
    isRedirect,
    shimmer,
    initial,
    type,
    ...componentData
  } = hookProps;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get('isProduct');
  const isCategory = type === 'CATEGORY';
  const isCeilingCategory = location.pathname === '/atomberg-ceiling-fans';

  if ((isLoading || isRedirect) && !import.meta.env.SSR) {
    // Show root component shimmer
    if (shimmer) {
      return <RootShimmerComponent type={shimmer} />;
    }
    return initial ? null : isCategory || !name ? (
      isCeilingCategory ? <ProductCategoryShimmer/>: <CategoryLoadShimmer />
    ) : (
      <RootShimmerComponent type={shimmer} />
    );
  } else if (RootComponent) {
    return (
      <Suspense
        fallback={
          isCategory && !name ? (
            isCeilingCategory ? <ProductCategoryShimmer/>: <CategoryLoadShimmer />
          ) : (
            <RootShimmerComponent type={shimmer} />
          )
        }
      >
        <RootComponent {...componentData} />
        <Outlet />
      </Suspense>
    );
  } else if (isNotFound) {
    window['notFound'] = true;
    return (
      <ErrorView
        message={MESSAGES.get('NOT_FOUND')}
      />
    );
  }

  return <ErrorView message={MESSAGES.get('INTERNAL_ERROR')} />;
};

export default MagentoRoute;
