export enum AnalyticsEvents {
  GA4_CLIENT_ID = 'GA4_CLIENT_ID',

  ADD_SHIPMENT_INFO_EVENT_NAME = 'add_shipping_info',
  ADD_SHIPMENT_INFO_ERROR_EVENT_NAME = 'add_shipping_info_error',

  BEGIN_CHECKOUT_EVENT_NAME = 'begin_checkout',
  BEGIN_CHECKOUT_ERROR_EVENT_NAME = 'begin_checkout_error',

  PURCHASE_EVENT_NAME = 'purchase',

  PAYMENT_COD_EVENT_NAME = 'add_payment_info_cod',
  COD_PAYMENT_UPDATE_ON_CART_EVENT_NAME = 'cod_payment_on_cart',
  COD_PAYMENT_UPDATE_ON_ERROR_CART_EVENT = 'cod_payment_on_cart_error',

  PAYMENT_RAZORPAY_EVENT_NAME = 'add_payment_info_razorpay',
  PAYMENT_RAZORPAY_ERROR_EVENT_NAME = 'add_payment_info_razorpay_error',
  RAZORPAY_PAYMENT_UPDATE_ON_CART_EVENT_NAME = 'razorpay_payment_on_cart',
  RAZORPAY_PAYMENT_UPDATE_ON_ERROR_CART_EVENT = 'razorpay_payment_on_cart_error',

  CART_UPDATED_EVENT_NAME = 'cart_updated',
  ADD_TO_CART_ERROR_EVENT_NAME = 'add_to_cart_error',
  REMOVE_FROM_CART_ERROR_EVENT_NAME = 'remove_from_cart_error',
  CART_CLEAT_EVENT_NAME = 'cart_clear',
  CART_CLEAR_ERROR_EVENT_NAME = 'cart_clear_error',

  PLACE_ORDER_EVENT_NAME = 'place_order',
  PLACE_ORDER_ERROR_EVENT_NAME = 'place_order_error',

  LOGIN_EVENT_NAME = 'login',
  USER_LOGGED_IN = 'User Logged In',
  CHECK_ORDER_EVENT_NAME = 'check_orders',
  SEARCHED_EVENT_NAME = 'searched',
  VIEW_CATEGORY_EVENT_NAME = 'view_category',
  VIEW_PRODUCT_EVENT_NAME = 'view_product',
  BUY_NOW_EVENT_NAME = 'buy_now',
  ADD_TO_CART_EVENT_NAME = 'add_to_cart',
  REMOVE_FROM_CART_EVENT_NAME = 'remove_from_cart',
  STORE_LOCATOR_EVENT_NAME = 'view_store_locator_page',
  VIEW_CART_EVENT_NAME = 'view_cart',
  CHECKOUT_DETAILS_SUBMITTED_EVENT_NAME = 'checkout_details_submitted',
  MAKE_PAYMENT_BUTTON_EVENT_NAME = 'make_payment_button',
  PAYEMENT_SUCCESSFULL_EVENT_NAME = 'payment_successful',
  PAYEMENT_FAILURE_EVENT_NAME = 'payment_failure',
  WHATS_ICON_CLICK_EVENT_NAME = 'whatsApp_icon_click',
  CALL_ICON_EVENT_NAME = 'call_icon_click',
  CHECK_DELIVERY_TIME_EVENT_NAME = 'check_delivery_time',
  STORE_LOCATOR_SEARCHED_EVENT_NAME = 'store_locator_searched',
  VIEW_WARRANTY_PAGE_EVENT_NAME = 'view_warranty_page',
  VIEW_SUPPORT_PAGE_EVENT_NAME = 'view_support_page',
  RAZORPAY_EMI_BOX_EVENT_NAME = 'razorpay_emi_box',
  REQUEST_LIVE_DEMO_EVENT_NAME = 'request_live_demo',
  USER_DETAILS_EVENT_NAME = 'user_details_updated'
}
