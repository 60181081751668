import React from 'react';
import GenericShimmer from 'src/components/GenericShimmer';
import styles from './style.module.scss';

const ProductCategoryShimmer: React.FC<any> = () => {
  return (
    <div className={`${styles.ceilingInitialShimmerContainer}`}>
      {Array(4)
        .fill('')
        ?.map((_, i) => {
          return (
            <div className={styles.shimmer} key={i}>
              <GenericShimmer height="100%" count={1} />
            </div>
          );
        })}
    </div>
  );
};

export default ProductCategoryShimmer;
