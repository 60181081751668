import { getSize } from 'packages/framework/hooks/useWindowSize';
import { AnalyticsEvents } from 'packages/framework/util/logger/constants';
import { gtmParameters } from '../getParameters';
import moengage from '@moengage/web-sdk';
import { checkEmailAddress } from 'packages/framework/hooks/checkEmailAddress';
import { CHEKOUT_METHOD_MAGIC_EVENT_KEY } from 'packages/payment-methods/razorpay/src/hooks/constants';


const getSizeValue = sweepsizeData => {
  const sizeData = sweepsizeData?.find(
    item => item?.option_label === 'Sweep Size',
  );
  return sizeData?.value_label;
};

export const gtm = gtmData => {
  console.log('__GTM PUSH__', gtmData.event, gtmData);
  try {
    window['google_tag_manager'][import.meta.env.VITE_GTM_ID].dataLayer.reset();
  } catch (e) {}
  try {
    window['dataLayer'].push(gtmData);
  } catch (e) {}
};

export const sendGtmEvent = (event, obj) => {
  const { isMobile } = getSize();
  const device = isMobile ? 'mobile' : 'desktop';
  return gtm({
    event,
    ...obj,
    device,
  });
};
function getCartItemsV2(cart) {
  return cart.items.map(
    ({
      product: { name, categories, sku },
      prices: {
        row_total: { value, currency },
      },
      quantity,
      configurable_options,
      configured_variant,
    }) => {
      // Send unit price
      let variant = configurable_options
        ? configurable_options.map(o => o.value_label).join(',')
        : undefined;

      let category =
        categories && categories[0] ? categories[0].name : undefined;
      let variantPrice =
        configured_variant?.price_range.minimum_price.regular_price.value;
      let retail_price = value / quantity;
      let discount = variantPrice - retail_price;
      return {
        item_id: sku,
        // item_list_id: 'ABC',
        item_name: name,
        // item_list_name: 'Ceiling Fan',
        item_brand: 'Atomberg', // static
        item_category: category,
        item_variant: variant,
        price: variantPrice,
        retail_price,
        quantity,
        discount: discount,
        currency: 'INR', // static
      };
    },
  );
}
export function getCartItems(cart) {
  return cart.items.map(
    ({
      product: { name, categories, sku },
      prices: {
        row_total: { value, currency },
      },
      quantity,
      configurable_options,
    }) => {
      // Send unit price
      let variant = configurable_options
        ? configurable_options.map(o => o.value_label).join(',')
        : undefined;

      let category =
        categories && categories[0] ? categories[0].name : undefined;

      return {
        name: name,
        price: value / quantity,
        sku,
        id: sku,
        variant,
        quantity,
        category,
      };
    },
  );
}

export const sendCartLoaded = cart => {
  try {
    let shipping_addresses = cart.shipping_addresses || [];
    let shipping_address = shipping_addresses[0] || {};

    gtm({
      event: 'cartLoaded',
      cart: {
        items: getCartItems(cart),
        address: shipping_address,
      },
    });
  } catch (e) {}
};

export const sendUserLoaded = user => {
  try {
    if (!user) {
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: false,
        },
      });
    } else {
      const { email, firstname, lastname } = user;
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: true,
          email,
          firstname,
          lastname,
        },
      });
    }
  } catch (e) {}
};
export const sendPageView = (page, rest = {}) => {
  try {
    setTimeout(() => {
      gtm({
        event: 'pageView',
        page,
        title: document.title,
        ...rest,
      });
    }, 100);
  } catch (e) {}
};
export const sendProductItemSelect = (product, category, price, variant) => {
  try {
    const { name, sku, categories } = product;
    gtm({
      event: 'select_item',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      ecommerce: {
        items: [
          {
            item_id: sku,
            item_name: name,
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
            item_variant: variant,
          },
        ],
      },
    });
  } catch (e) {}
};
export const sendProductDetailImpressions = (product, price, variant) => {
  try {
    const { name, sku, categories } = product;
    let category = categories && categories[0] ? categories[0].name : undefined;
    gtm({
      event: 'view_item',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      items: [
        {
          id: sku,
        },
      ],
      ecommerce: {
        // Digital RIO
        items: [
          {
            item_id: sku,
            // item_list_id: 'ABC',
            item_name: name,
            // item_list_name: 'Ceiling Fan',
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
            item_variant: variant,
          },
        ],
        detail: {
          // actionField: { list: 'Apparel Gallery' }, // 'detail' actions have an optional list property.
          products: [
            {
              name,
              id: sku,
              sku,
              price: price ? price.value : undefined,
              variant,
              category,
            },
          ],
        },
      },
    });
  } catch (e) {}
};
export const sendProdutListImpression = (category, products) => {
  try {
    // sendPageView(window.location.href)
    gtm({
      event: 'Category View',
      category,
      item_count: products.length,
    });
    gtm({
      event: 'view_item_list',
      ecommerce: {
        currency: 'INR', // Local currency is optional.
        impressions: products.map((product, index) => ({
          name: product.name,
          id: product.sku,
          sku: product.sku,
          price: product.price_range.minimum_price.final_price.value,
          category,
          position: +index + 1,
        })),
        items: products.map((product, index) => {
          let price = product.price_range.minimum_price;
          return {
            // item_list_id: 'ABC',
            // item_list_name: 'Ceiling Fan',
            item_id: product.sku,
            item_name: product.name,
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
          };
        }),
      },
    });
  } catch (e) {}
};

export const findCartItem = (cartItems, cartItemInput) => {
  let { selected_options, sku, id } = cartItemInput;
  return cartItems.find(item => {
    let found = false;

    if (item.product.sku == sku) {
      found = true;
      if (item.configurable_options) {
        for (let {
          configurable_product_option_value_uid,
        } of item.configurable_options) {
          if (
            !selected_options.includes(configurable_product_option_value_uid)
          ) {
            found = false;
          }
        }
      }
    } else if (item.uid == id) {
      found = true;
    }
    return found;
  });
};

export let cartEventMap = {
  add: 'add_to_cart',
  remove: 'remove_from_cart',
};

export const sendCartEvents = (
  cartItems,
  cartItemInput,
  action: 'add' | 'remove',
  removedItem = null,
) => {
  try {
    let { quantity } = cartItemInput;

    let cartItem = findCartItem(cartItems, cartItemInput);

    if (!cartItem && !removedItem) return null;

    let {
      product: { name, categories, sku },
      prices: {
        price: { value, currency },
      },
      configurable_options,
      configured_variant,
    } = cartItem || removedItem;

    let variant = configurable_options
      ? configurable_options.map(o => o.value_label).join(',')
      : undefined;
    let variantPrice =
      configured_variant?.price_range.minimum_price.regular_price.value;
    let discount = variantPrice - value;

    let category = categories && categories[0] ? categories[0].name : undefined;

    gtm({
      event: cartEventMap[action],
      ecommerce: {
        currency: currency,
        value: value * quantity,
        items: [
          {
            id: sku,
            item_id: sku,
            item_name: name,
            item_brand: 'Atomberg',
            item_category: category,
            item_variant: variant,
            price: variantPrice,
            retail_price: value,
            discount: discount,
            currency: 'INR',
            quantity,
          },
        ],
        [action]: {
          products: [
            {
              name,
              price: value,
              id: sku,
              sku,
              variant,
              quantity,
              category,
            },
          ],
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendCheckoutEventV2Data = (cart, additional = {}) => {
  const shippingAddress = cart.shipping_addresses && cart.shipping_addresses[0];
  const value = cart.prices.grand_total.value;

  const discount = (cart.prices.discounts || []).reduce((a, d) => {
    return a + d.amount.value;
  }, 0);

  let ecommerce: any = {
    value,
    currency: 'INR',
    checkout_method: cart?.checkout_method || '',
    discount,
    quanitity: cart.total_quantity,
    items: getCartItemsV2(cart),
    ...additional,
  };

  if (cart.email) {
    ecommerce.email_id = cart.email;
  }

  if (shippingAddress) {
    const { city, firstname, lastname, postcode, region, street, telephone } =
      shippingAddress;
    ecommerce.first_name = firstname;
    ecommerce.last_name = lastname;
    ecommerce.phone_number = telephone;
    ecommerce.address = street.join(' ');
    ecommerce.zip_code = postcode;
    ecommerce.city = city;
    ecommerce.region = region.label;
  }
  if (cart.applied_coupons) {
    ecommerce.coupon = cart.applied_coupons[0]?.code;
  }
  return ecommerce;
};

export const sendCheckoutEventV2 = (event, cart, additional = {}) => {
  try {
    gtm({
      event,
      ecommerce: sendCheckoutEventV2Data(cart, additional),
    });
  } catch (e) {
    console.log(e);
  }
};
export const sendSheckoutEvent = (event, cart, action) => {
  try {
    const checkout: any = {
      actionField: action, //{ step, option },
      // products: getCartItems(cart),
    };
    if (cart) {
      checkout.products = getCartItems(cart);
    }
    gtm({
      event: 'checkout',
      ecommerce: {
        checkout,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const purchaseEvent = async (cart, order_number) => {
  try {
    let tax =
      cart.prices.cgst_charge?.value +
      cart.prices.igst_charge?.value +
      cart.prices.sgst_charge?.value;

    let revenue = cart.prices.grand_total.value;

    let shippingAddress = (cart.shipping_addresses || [])[0] || {};
    let userId = await sha256(`91${shippingAddress?.telephone}`);

    let shipping_amount =
      shippingAddress?.selected_shipping_method?.amount.value;
    gtm({
      event: 'purchase',
      userId,
      transaction_id: order_number,
      order_value: revenue,
      order_id: cart.id,
      checkout_method: cart?.checkout_method || '',
      enhanced_conversion_data: {
        email: cart.email,
      },
      ecommerce: {
        ...sendCheckoutEventV2Data(cart, {
          transaction_id: order_number,
        }),
        purchase: {
          actionField: {
            userId,
            id: order_number, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue,
            tax: tax,
            shipping: shipping_amount,
            coupon: (cart.applied_coupons || []).map(c => c.code).join('|'),
          },
          products: getCartItems(cart),
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const lastTouchAttribution = () => {
  let trackingParamList = [
    'gclid',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];
  var sP = new URLSearchParams(window.location.search);
  var params = trackingParamList.reduce((m, key) => {
    if (sP.has(key)) {
      m[key] = sP.get(key);
    }
    return m;
  }, {});
  if (Object.keys(params).length > 0) {
    trackingParamList.forEach(key => {
      localStorage.removeItem(`lt_${key}`);
      if (params[key]) {
        localStorage.setItem(`lt_${key}`, params[key]);
      }
    });
  }
};

export async function sha256(message) {
  if (!message) return '';
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function handleMagicCheckoutEvents(
  data: any,
  setUserData: any,
  userToken: any,
  productInfo,
  methodRef,
  triggerMoengageUserAttribute
) {
  if (!data || !data?.event) return;
  // const value = cart.prices.grand_total.value;
  const paymentMode = 'magic_checkout';
  const brand = 'Atomberg';
  let dataToSend: any = {};
  const value = data?.totalAmount / 100 || 0;
  const currency = data?.currency || 'INR';
  const discount = (productInfo?.cart.prices.discounts || []).reduce((a, d) => {
    return a + d.amount.value;
  }, 0);
  const quantity =
    data?.lineItems?.reduce(
      (curr: any, prev: any) => curr + (prev?.quantity || 0),
      0,
    ) || 0;
  switch (data.event) {
    case 'initiate': {
       const eventData = {
         category_name: [],
         product_name: [],
         quantity: [],
         product_colour: [],
         product_id: [],
         ...gtmParameters(),
       };
       productInfo?.cart?.items.forEach(item => {
         eventData.category_name.push(item?.product?.categories?.[0]?.name);
         eventData.product_name.push(item?.product?.name);
         eventData.quantity.push(item?.quantity);
         eventData?.product_id.push(item?.configured_variant?.sku)
         eventData.product_colour.push(
           item?.configurable_options?.[0]?.value_label,
         );
       });
       moengage.track_event(AnalyticsEvents?.BEGIN_CHECKOUT_EVENT_NAME, eventData);
      dataToSend = {
        event: AnalyticsEvents.BEGIN_CHECKOUT_EVENT_NAME,
        paymentMode: paymentMode,
        ecommerce: {
          value: value,
          currency: currency,
          checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
          discount: discount,
          quanitity: quantity,
          coupen: productInfo?.cart?.applied_coupons?.[0]?.code || '',
          items: productInfo?.cart?.items.map((item: any) => {
            let category = item?.product?.categories[0]
              ? item?.product?.categories?.[0]?.name
              : undefined;
            const variant = item?.configurable_options?.[0]?.value_label;
            let variantPrice =
              item?.configured_variant?.price_range?.minimum_price
                ?.regular_price?.value;
            let retail_price =
              item?.prices?.row_total?.value / item?.quantity || 0;
            let discount = variantPrice - retail_price;
            return {
              item_id: item?.product?.sku || '',
              item_name: item?.product?.name || '',
              item_brand: brand,
              item_category: category, //? not coming from magic checkout
              item_variant: variant, //? variant not coming, variant_id is present
              price: variantPrice || 0,
              retail_price,
              quantity: item?.quantity,
              discount: discount || 0,
              currency: currency,
            };
          }),
        },
      };
    }
      break;

    case 'coupon_failed':
      dataToSend = {
        event: 'coupon code failed',
        paymentMode: paymentMode,
        coupon_code: data?.couponCode || '',
        message: data?.errorMsg || '',
        page_url: window?.location?.href || '',
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
      };
      break;

    case 'coupon_applied':
      dataToSend = {
        event: 'Coupon Applied',
        paymentMode,
        cart_value_before_discount: data?.amountBeforeDisc / 100 || 0,
        cart_value_after_discount: data?.amountAfterDisc / 100 || 0,
        coupon_code: data?.appliedCouponCode || '',
        discount_amount: data?.couponDiscountValue / 100 || 0,
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
      };
      break;

    case 'otp_initiated': {
      dataToSend = {
        event: 'otp_initiated',
        isScriptCouponApplied: data?.isScriptCouponApplied,
        phone: data?.phone,
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
        email: data?.email,
        state: data?.state,
        city: data?.city,
        lineItems: data?.lineItems?.map((item: any) => ({
          sku: item?.sku || '',
          item_name: item?.name || '',
          price: item?.price / 100 || 0,
          type: item?.type,
          quantity: item?.quantity,
          image_url: item?.image_url,
          variant_id: item?.variant_id,
          description: item?.description,
          product_url: item?.product_url,
        })),
        first_name: data?.first_name,
        last_name: data?.last_name,
        otp_verified: data?.otp_verified,
        totalAmount: data?.totalAmount / 100,
        latestTotal:data?.latestTotal / 100,
        couponDiscountValue: data?.couponDiscountValue,
        currency: data?.currency,
      };
    }
    break;
    case 'otp_submitted': {
      dataToSend = {
        event: 'otp_submitted',
        isScriptCouponApplied: data?.isScriptCouponApplied,
        phone: data?.phone,
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
        email: data?.email,
        state: data?.state,
        city: data?.city,
        lineItems: data?.lineItems?.map((item: any) => ({
          sku: item?.sku || '',
          item_name: item?.name || '',
          price: item?.price / 100 || 0,
          type: item?.type,
          quantity: item?.quantity,
          image_url: item?.image_url,
          variant_id: item?.variant_id,
          description: item?.description,
          product_url: item?.product_url,
        })),
        first_name: data?.first_name,
        last_name: data?.last_name,
        otp_verified: data?.otp_verified,
        totalAmount: data?.totalAmount / 100,
        latestTotal:data?.latestTotal / 100,
        couponDiscountValue: data?.couponDiscountValue,
        currency: data?.currency,
      };
    }
    break;

    case 'shipping_selected': {
      let eventData = {
        category_name: [],
        product_name: [],
        quantity: [],
        product_colour: [],
        size: [],
        name: '',
        email_id: '',
        phone_number: '',
        city: '',
        pincode: '',
        product_id: [],
      };
      productInfo?.cart?.items?.forEach(item => {
        const size = getSizeValue(item?.configurable_options) || '';
        eventData.category_name.push(item?.product?.categories?.[0]?.name);
        eventData.product_id.push(item?.configured_variant?.sku);
        eventData.product_name.push(item?.product?.name);
        eventData.quantity.push(item?.quantity);
        eventData.size.push(size);
        eventData.product_colour.push(
          item?.configurable_options?.[0]?.value_label,
        );
      });
      eventData = {
        ...eventData,
        name: `${data?.first_name} ${data?.last_name}`,
        phone_number: data?.phone,
        city: data?.city,
        pincode: data?.zipcode,
        email_id: checkEmailAddress(data?.email),
      };
      triggerMoengageUserAttribute();
      moengage.track_event(
        AnalyticsEvents?.CHECKOUT_DETAILS_SUBMITTED_EVENT_NAME,
        eventData,
      );
      dataToSend = {
        event: 'add_shipping_info',
        paymentMode,
        ecommerce: {
          value: value,
          currency: currency,
          discount: discount,
          checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
          quanitity: quantity,
          items: productInfo?.cart?.items.map((item: any) => {
            let category = item?.product?.categories[0]
              ? item?.product?.categories[0].name
              : undefined;
            const variant = item?.configurable_options?.[0]?.value_label;
            let variantPrice =
              item?.configured_variant?.price_range.minimum_price.regular_price
                .value;
            let retail_price =
              item?.prices?.row_total?.value / item?.quantity || 0;
            let discount = variantPrice - retail_price;
            return {
              item_id: item?.product?.sku || '',
              item_name: item?.product?.name || '',
              item_brand: brand,
              item_category: category, //? not coming from magic checkout
              item_variant: variant, //? variant not coming, variant_id is present
              price: variantPrice || 0,
              retail_price,
              quantity: item?.quantity,
              discount: discount || 0,
              currency: currency,
            };
          }),
          email_id: data?.email || '',
          first_name: data?.first_name || '',
          last_name: data?.last_name || '',
          phone_number: data?.phone || '',
          address: `${data?.line1} ${data?.line2}` || '',
          zip_code: data?.zipcode || '',
          city: data?.city || '',
          region: data?.state || '',
          coupen:
            data?.appliedCouponCode ||
            productInfo?.cart?.applied_coupons?.[0]?.code,
        },
      };
    }
      break;

    case 'payment_initiated': {
      methodRef.current = data?.paymentMethod;
      dataToSend = {
        event: 'add_payment_info',
        paymentMode,
        ecommerce: {
          value: value,
          currency: currency,
          discount: discount,
          quanitity: quantity,
          checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
          items: productInfo?.cart?.items.map((item: any) => {
            let category = item?.product?.categories[0]
              ? item?.product?.categories[0].name
              : undefined;
            const variant = item?.configurable_options?.[0]?.value_label;
            let variantPrice =
              item?.configured_variant?.price_range.minimum_price.regular_price
                .value;
            let retail_price =
              item?.prices?.row_total?.value / item?.quantity || 0;
            let discount = variantPrice - retail_price;
            return {
              item_id: item?.product?.sku || '',
              item_name: item?.product?.name || '',
              item_brand: brand,
              item_category: category, //? not coming from magic checkout
              item_variant: variant, //? variant not coming, variant_id is present
              price: variantPrice || 0,
              retail_price,
              quantity: item?.quantity,
              discount: discount || 0,
              currency: currency,
            };
          }),
          payment_type: 'razorpay',
          email_id: data?.email || '',
          first_name: data?.first_name || '',
          last_name: data?.last_name || '',
          phone_number: data?.phone || '',
          address: `${data?.line1} ${data?.line2}` || '',
          zip_code: data?.zipcode || '',
          city: data?.city || '',
          region: data?.state || '',
          coupen:
            data?.appliedCouponCode ||
            productInfo?.cart?.applied_coupons?.[0]?.code,
        },
      };
    }
      break;

    case 'payment_failed': {
      dataToSend = {
        event: 'payment failure',
        paymentMode,
        reason: data?.failureReason || '',
        payment_mode: '', //? not coming in payload
        total_amount: value,
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY,
      };
      let eventData = {
        category_name: [],
        product_name: [],
        quantity: [],
        sale_price: [],
        regular_price: [],
        payment_method: [],
        name: '',
        email_id: '',
        phone_number: '',
        coupon_code_applied: '',
        coupon_code_amount: '',
        coupon_code_name: '',
        order_amount: '',
        ...gtmParameters(),
      };
      productInfo?.cart?.items.forEach(item => {
        eventData.category_name.push(item?.product?.categories?.[0]?.name);
        eventData.product_name.push(item?.product?.name);
        eventData.quantity.push(item?.quantity);
        eventData.sale_price.push(
          item?.product?.price_range?.minimum_price?.final_price?.value,
        );
        eventData.regular_price.push(
          item?.product?.price_range?.minimum_price?.regular_price?.value,
        );
      });
      eventData = {
        ...eventData,
        coupon_code_applied: productInfo?.cart?.applied_coupons?.length
          ? 'Yes'
          : 'No',
        coupon_code_amount: '',
        coupon_code_name: productInfo?.cart?.applied_coupons?.[0]?.code || '',
        name: data?.first_name,
        email_id: checkEmailAddress(data?.email),
        payment_method: methodRef?.current,
        order_amount: productInfo?.cart?.prices?.grand_total?.value,
        phone_number: data?.phone,
      };
      triggerMoengageUserAttribute();
      moengage.track_event(
        AnalyticsEvents?.PAYEMENT_FAILURE_EVENT_NAME,
        eventData,
      );
    }
      break;
    case 'default':
      break;
  }
  dataToSend.event && sendGtmEvent(dataToSend.event, dataToSend);

  if (data.event === 'user_data') {
    if (!data?.first_name && !userToken) {
      sendGtmEvent('UserData', {
        user_status: 'Guest User',
        paymentMode,
      });
    } else {
      const phoneWithoutPlusSign = data?.phone?.split('+')?.[1];
      const userId = phoneWithoutPlusSign
        ? await sha256(phoneWithoutPlusSign)
        : '';

      setUserData({
        phone: data?.phone || '',
        email: data?.email || '',
        userId,
        firstname: data?.first_name || '',
        lastname: data?.last_name || '',
        city: data?.city || '',
        postcode: data?.zipcode || '',
        region: data?.state || '',
        street: [data?.line1 || '', data?.line2 || ''],
        telephone: data?.phone || '',
      });

      sendGtmEvent('UserData', {
        user_status: userToken ? 'Logged In' : 'Guest User',
        paymentMode,
        total_no_of_orders: 0, //? not receiving in payload
        mode: !!data?.phone ? 'mobile' : 'email',
        phone: data?.phone || '',
        userId: userId,
        email: data?.email || '',
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        date_of_birth: '', //? not receiving in payload
        gender: '', //? not receiving in payload
        city: data?.city || '',
        state: data?.state || '',
        address: `${data?.line1 || ''} ${data?.line2 || ''}` || '',
        last_order_date: '', //? not receiving in payload
        loyalty_points: 0, //? not receiving in payload
        total_order_value: value,
        checkout_method: CHEKOUT_METHOD_MAGIC_EVENT_KEY
      });
    }
  }
}
