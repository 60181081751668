import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface GenericShimmerTypes {
  width?: string | number;
  height: string | number;
  count: number;
  classes?: string
  circle?: boolean
}

export const GenericShimmer = ({width, height, count = 1, classes, circle=false }:GenericShimmerTypes) => {
  return <Skeleton  width={width} height={height} count={count} circle={circle} />;
};

export default GenericShimmer;
