import { gql } from '@apollo/client';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { useAwaitQuery } from '../hooks/useAwaitQuery';
import { useEventListener } from '../hooks/useEventListener';

import actions from '../store/actions/user/actions';
import * as asyncActions from '../store/actions/user/asyncActions';
import bindActionCreators from '../util/bindActionCreators';
import BrowserPersistence from '../util/simplePersistence';

const UserContext = createContext<any>({});

const UserContextProvider = props => {
  const { actions, asyncActions, children } = props;

  const userApi = useMemo(
    () => ({
      actions,
      ...asyncActions,
    }),
    [actions, asyncActions],
  );

  const fetchUserDetails = useAwaitQuery(GET_CUSTOMER);

  // const contextValue = useMemo(() => [userState, userApi], [userApi, userState]);

  useEffect(() => {
    // check if the user's token is not expired
    const storage = new BrowserPersistence();
    const item = storage.getRawItem('signin_token');

    if (item) {
      // To get user details on load
      userApi.getUserDetails({
        fetchUserDetails,
      });

      const { ttl, timeStored } = JSON.parse(item);
      const now = Date.now();

      // if the token's TTYL has expired, we need to sign out
      if (ttl && now - timeStored > ttl * 1000) {
        asyncActions.signOut();
      }
    }
  }, [userApi, fetchUserDetails, asyncActions]);

  // useEffect(()=>{
  //   let event = new Event('signOut');
  //       window.dispatchEvent(event)

  // },[asyncActions])

  // const storageListener = useCallback(() => {
  //   asyncActions.signOut();
  // }, [asyncActions]);

  // useEventListener(globalThis, 'storage', storageListener);

  return <UserContext.Provider value={userApi}>{children}</UserContext.Provider>;
};

const mapStateToProps = null; //({ user }) => ({ userState: user });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContextProvider);

/**
 * @typedef {Object} UserState
 *
 * @property {CurrentUser} currentUser Current user details
 * @property {Error} getDetailsError Get Details call related error
 * @property {Boolean} isGettingDetails Boolean if true indicates that user details are being fetched. False otherwise.
 * @property {Boolean} isResettingPassword Deprecated
 * @property {Boolean} isSignedIn Boolean if true indicates that the user is signed in. False otherwise.
 * @property {Error} resetPasswordError Deprecated
 *
 */

/**
 * @typedef {Object} CurrentUser
 *
 * @property {String} email Current user's email
 * @property {String} firstname Current user's first name
 * @property {String} lastname Current user's last name
 */

/**
 * @typedef {Object} UserActions
 *
 * @property {Function} clearToken Callback to clear user token in browser persistence storage
 * @property {Function} getUserDetails Callback to get user details
 * @property {Function} resetPassword Deprecated
 * @property {Function} setToken Callback to set user token in browser persistence storage
 * @property {Function} signOut Callback to sign the user out
 */

/**
 * @returns {[UserState, UserActions]}
 */
export const useUserContext = () => useContext(UserContext);
export const useUserState = () => useSelector((state: any) => state.user);

const GET_CUSTOMER = gql`
  query GetCustomerDetails {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      email
      firstname
      lastname
      is_subscribed
      checkout_payment_method
      mobilenumber
      orders {
        total_count
      }
    }
  }
`;
