import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getSearchParam } from 'packages/framework/hooks/useSearchParam';
import { useDropdown } from 'packages/framework/hooks/useDropdown';
import moengage from '@moengage/web-sdk';
import { AnalyticsEvents } from 'packages/framework/util/logger/constants';

export const useSearchBar = () => {
  const { setValue, handleSubmit: submitForm, getValues } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const searchQuery = getSearchParam('query', location).replace(/\.[^.]*$/, '');
  const inputText = getValues('search_query');

  const {
    elementRef: containerRef,
    expanded: isAutoCompleteOpen,
    setExpanded: setIsAutoCompleteOpen,
  } = useDropdown();

  const setInputSearchValue = useCallback(
    value => {
      setValue('search_query', value);
      // submitForm(value);
    },
    [setValue, submitForm, inputText],
  );

  // compare search input value with query and set similar
  useEffect(() => {
    if (searchQuery && searchQuery !== inputText) {
      setValue('search_query', searchQuery);
    }
  }, [searchQuery, setValue]);

  const handleQuerySubmit = useCallback(
    ({ search_query }) => {
        moengage.track_event(AnalyticsEvents?.SEARCHED_EVENT_NAME, {
          keyword: search_query
          });
      if (search_query != null && search_query.trim().length > 0) {
        navigate(`/search?query=${search_query}`);
        setIsAutoCompleteOpen(false);
      }
    },
    [navigate, setIsAutoCompleteOpen],
  );

  return {
    setInputSearchValue,
    handleQuerySubmit,
    containerRef,
    isAutoCompleteOpen,
    setIsAutoCompleteOpen,
  };
};
