import GenericShimmer from 'src/components/GenericShimmer';
import styles from './style.module.scss';

interface CardShimmerTypes {
	index?: number
}

const CardShimmer = ({index}:CardShimmerTypes) => {
  return (
    <div className={styles.categoryCardShimmer} key={index}>
      {Array(5)
        .fill('')
        .map((_, i) => {
          return (
            <div className={styles.cardShimmer} key={i}>
              <div className={styles.imageShimmer}>
                <GenericShimmer height="100%" width="100%" count={1} />
              </div>
              <div className={styles.productNameShimmer}>
                <GenericShimmer height="100%" width="100%" count={1} />
              </div>
              <div className={styles.productVariant}>
                <GenericShimmer height={20} width="100%" count={1} />
                <GenericShimmer height={20} width="100%" count={1} />
              </div>
              <div className={styles.priceStockStatusShimmer}>
                <div className={styles.priceShimmer}>
                  <GenericShimmer height={20} width="100%" count={1} />
                </div>
                <div className={styles.stockStatusShimmer}>
                  <GenericShimmer height="100%" width="100%" count={1} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CardShimmer;
